import * as React from 'react'
import PropTypes from 'prop-types'
import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  Container,
  Overlay,
  Content,
  Subtitle,
  Title,
  Text,
  Button
} from './style'
import { BANNER_INTERACTION } from '@/utils/gtmEvents'

const Item = ({
  subtitle = '',
  title = '',
  text = '',
  ctaLabel = '',
  ctaTo = '',
  background
}) => {
  const triggerGtmEvent = (ctaName, bannerName) => {
    typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
      event: BANNER_INTERACTION,
      cta_name: ctaName,
      banner_name: bannerName
    })
  }
  return (
    <Container background={ resolveAssetURL(background) }>
      <Content>
        <Subtitle>{ subtitle }</Subtitle>
        <Title>{ title }</Title>
        <Text>{ text }</Text>
        <Button
          onClick={ () => triggerGtmEvent(ctaLabel, title) }
          to={ ctaTo }
        >
          { ctaLabel }
        </Button>
      </Content>
    </Container>
  )
}

Item.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaTo: PropTypes.string,
  background: PropTypes.string.isRequired
}

export default Item
